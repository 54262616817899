import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';
import SubFeatureDetail from '../components/subfeature-detail';



import Ready from '../components/ready';

import illuReady from '../images/illu-ready.svg';
import illuHero from "../images/connect-the-block_top.svg"
import illuHero2 from "../images/sub-product-tour-1.svg"

const ContentSample = () => {

  const smartMenuAnimation = '/animations/2020-09-Ludus-02-smartMenu.json';
  const copyPasteAnimation = '/animations/2020-09-Ludus-09-copyPaste_EDIT01.json';
  const chartsAnimation = '/animations/2020-09-Ludus-03-charts_EDIT01.json';

  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null
  });

  return (
    <Layout
      socialDark={true}
      currentPage='connect-the-blocks'
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Connect the blocks" />

      <Section>

        <div
          style={{
            display: "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === 'mobile' ? '43%' : '24%',
            paddingBottom: currentBreakPoint.type === "mobile" ? '75%' : '27%',
            top: currentBreakPoint.type === 'mobile' ? '130px' : '190px',
            left: '0',
            background: `transparent url(${illuHero}) no-repeat 0 50%`,
            backgroundSize: "contain",
          }}
        />

        <ContentHero
          headlineLabel="Product tour"
          headlineUrl="/product-tour"
          title="Connect the blocks"
          text={`You can have the most powerful editing app, but the final goal is always the same: tell the good story. We believe telling the story is very different than designing a slide. You want to drop your content as it comes directly in your slides... `}
        />
      </Section>

      <Section>

      <div
          style={{
            display: "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? '40%' : '20%',
            paddingBottom: currentBreakPoint.type === "mobile" ? '75%' : '27%',
            top: '-5%',
            right: '0',
            background: `transparent url(${illuHero2}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />

        <SubFeatureDetail
          title="Smart Menu"
          text="The smart menu is the fastest way to search all your possibilities: the perfect image on Unsplash, the latest version of your logo, that chart with that key insight, that funny gif... Added in your slide in 1 click. "
          details={[
            {
              title: 'Third party services',
              text: 'We connect with a long list of services: you can search in Unsplash, iconscout, giphy, etc. Without leaving Ludus and your presentation.'
            },
            {
              title: 'Smart blocks',
              text: `If you or your team saved a few assets as smart blocks, you'll find them in this menu. Neatly organised by tags... It can be an image, a logo, a layout composition, anything.`
            },
            {
              title: 'Everything',
              text: 'You can use that menu for adding all our block types: basic shapes, text, lists, charts, code, video, image... And you can do all that without even using your mouse. Keyboard masters will love it!'
            }
          ]}
          animation={smartMenuAnimation}
        />
      </Section>

      <Section>
        <SubFeatureDetail
          reverse={true}
          title="Add anything from anywhere "
          text="Nowadays, the content of a presentation can come from anywhere. There is so many fantastic online services... But connecting them together is not always straightforward, and you don't want your slide to just be a link repository... "
          details={[
            {
              title: 'Magic Copy paste ',
              text: 'When you paste a URL, we automatically detect if we support the service. So for example if you copy and paste a YouTube URL, Ludus will create a video block in your slide. It works with various type of URL (Figma, Invision, Instagram, Google spreadsheet, Google map, unsplash, soundclound, etc).'
            },
            {
              title: 'Like a native app',
              text: `You can also copy paste images, but you can even copy paste and SVG directly from Figma or Illustrator if you want. It's like you're using a good old desktop app. `
            },
            {
              title: 'Drag and drop like crazy',
              text: `You have a video or an image on your desktop? Just drag and drop it directly in your slide, we'll then compress the image or video to the best format. Pro tip: we have a high quality setting, for those pixel perfect maniacs 😇`
            }
          ]}
          animation={copyPasteAnimation}
        />
      </Section>

      <Section>
        <SubFeatureDetail
          title="Charts"
          text="You want to show your numbers the best way? We may have a thing for you... Or in fact we have a lot of things for you."
          details={[
            {
              title: 'Integrated charts',
              text: `You can paste tabular data (from your favorite spreadsheet tool) to generate a chart. Editing the data and params are done directly in Ludus via markdown or CSV format. Of course it's interactive so people can mouse over to get more details on that nice hockey curve.`
            },
            {
              title: 'Google sheets',
              text: `You can embed a google charts from a spreadsheet. It's an iframe, so it will always be synced with your latest numbers!`
            },
            {
              title: 'Advanced use case?',
              text: `And if you need a very special chart, you can go as crazy as you want by using your favorite library in a codepen and embed it in Ludus... We told you, only limited by your imagination 😇`
            }
          ]}
          animation={chartsAnimation}
        />
      </Section>

      <Section>
        <br /><br /><br /><br /><br /><br /><br /><br /><br />
      </Section>

      <Section
        light={true}
      >
        <div style={{
          display: (currentBreakPoint.bp === 'm' || currentBreakPoint.bp === 's' || currentBreakPoint.bp === 'xs') ? 'none' : 'block',
          position: 'absolute',
          zIndex: '4',
          width: '35%',
          paddingBottom: '35%',
          bottom: '0',
          right: '0',
          background: `transparent url(${illuReady}) no-repeat 100% 50%`,
          backgroundSize: 'contain',
        }} />
        <Ready />
      </Section>

      
    </Layout>
  );
};

export default ContentSample;
